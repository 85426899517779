// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: 'AIzaSyAWNuiGz2DKhqc33pFTCgb_r6jpOiChYkk',
	authDomain: 'loyalty-rewards-b567c.firebaseapp.com',
	projectId: 'loyalty-rewards-b567c',
	storageBucket: 'loyalty-rewards-b567c.appspot.com',
	messagingSenderId: '778442901166',
	appId: '1:778442901166:web:9d61e8eb463a7571f78f3f',
	measurementId: 'G-KLYKKWLVH9',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
export const db = getFirestore(app);
export const auth = getAuth(app);
