//imports
import { doc, getDoc, setDoc, updateDoc, arrayUnion, arrayRemove, onSnapshot } from 'firebase/firestore';
import { db } from '../../utils/firebase';
import { MerchantAccountInfo, MerchantRewardData } from '../../interfaces/interfaces';

// required if you want to keep logged in after user exits the browser or closes tab
// setPersistence(auth,  browserLocalPersistence);

// Check is the merchant exists in the merchants table
export const doesMerchantExist = async (merchantId: string) => {
  // Calling docRef will be empty, if doc with merchantId does not exist.
  const docRef = doc(db, 'merchants', merchantId);
  const docSnap = await getDoc(docRef);

  // Return (boolean) if docRef exists
  return docSnap.exists();
};

// After merchant DOES exist, check if it has been registered to an account.
export const isMerchantRegistered = async (merchantId: string) => {
  const docRef = doc(db, 'merchants', merchantId);
  const docSnap = await getDoc(docRef);
  const merchantData = docSnap.data();
  const isMerchantRegistered: boolean = merchantData?.isRegistered;

  return isMerchantRegistered;
}

// Switch the isRegistered flag for merchants true/false.
export const setIsMerchantRegistered = async (merchantId: string, isRegistered: boolean) => {
  const docRef = doc(db, 'merchants', merchantId);
  try {
    await setDoc(docRef, { isRegistered: isRegistered }, { merge: true })
  } catch (err) {
    console.log(err);
  }
}

// When a merchant account is created, set the details in the merchants-account document to save the merchant ID and email.
export const setMerchantAccountInfo = async (merchantAccountId: string, merchantId: string, email: string) => {
  const docRef = doc(db, 'merchant-accounts', merchantAccountId);
  try {
    await setDoc(docRef, { merchantId: merchantId , email: email }, { merge: true });
  } catch (err) {
    console.log('Error while trying to set merchant account info: ', err);
  }
}

// Add reward to merchant rewards array
export const addReward = async (merchantId: string, { name, points, description }: MerchantRewardData) => {
  const docRef = doc(db, 'merchants', merchantId);
  try {
    await updateDoc(docRef, { rewards: arrayUnion({ name: name, points: points, description: description })});
  } catch (err) {
    console.log('Error while trying to remove merchant reward: ', err);
  }
}

export const deleteReward = async (merchantId: string, { name, points, description }: MerchantRewardData) => {
  const docRef = doc(db, 'merchants', merchantId);
  try {
    await updateDoc(docRef, { rewards: arrayRemove({ name: name, points: points, description: description })});
  } catch (err) {
    console.log('Error while trying to add merchant reward: ', err);
  }
}

// Get the merchant ID associated with the merchant account.
export const getMerchantId = async (merchantAccountId: string) => {
  const docRef = doc(db, 'merchant-accounts', merchantAccountId);
  try {
    const docSnap = await getDoc(docRef);
    const merchantAccountData = docSnap.data();
    const merchantId = merchantAccountData?.merchantId;
    return merchantId;
  } catch (err) {
    console.log('Error while trying to get merchant ID: ', err);
  }
}

// Since firestore typically returns the whole snapshot, just "get the whole thing" and use needed fields from it.
export const getMerchantData = async (merchantId: string) => {
  const docRef = doc(db, 'merchants', merchantId);
  try {
    const docSnap = await getDoc(docRef);
    const merchantData = docSnap.data();
    return merchantData;
  } catch (err) {
    console.log('Error while trying to get merchant data: ', err);
  }
}

// Attach listener to listen to changes in merchant docs to make updates to the rewards table on add/delete
export const attachMerchantsListener = async (merchantId: string) => {
  const docRef = doc(db, 'merchants', merchantId);
  let rewards;
  const unsubscribe = onSnapshot(docRef, (snapshot) => {
    rewards = snapshot.data()?.rewards; 
  })
  return {rewards, unsubscribe}
}