import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import SnackbarAlert from '../../components/SnackbarAlert';

export default function HelpCenterPage() {

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        {/* Recent Orders */}
        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              How does the loyalty program work?
            </AccordionSummary>
            <AccordionDetails>
              Users will create an account and log into Piggy. Once on the app, they will need to link their credit card within the app. 
              When they make purchases at your business using the linked card, they will automatically accrue reward points to your business once the transaction is processed.
              This usually takes about 2-3 days.
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              Do user rewards points expire?
            </AccordionSummary>
            <AccordionDetails>
              Currently once a user accrues points at your business they will remain on the user's account until redeemed. In the future, points will have some form of expiration.
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              Can the dollar spent to points awarded ratio be customized?
            </AccordionSummary>
            <AccordionDetails>
              Right now the dollar spent to points awarded ratio cannot be customized. The ratio currently is for every $1 spent at your business the user will be rewarded 10 points ($1 = 10 points).
              Instead of customizing the ratio, you can customize the value of the points of a reward to achieve the same results. For example: If you want a user to spend $100 to be able to redeem a free slice of pizza,
              you would set the points required for a free slice of pizza to be 1000 points.
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              What do the top panels on the dashboard mean?
            </AccordionSummary>
            <AccordionDetails>
              If you hover your cursor on any of the panels, they will show a detailed explanation of the metric it displays.
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              How can I contact support for questions or assistance?
            </AccordionSummary>
            <AccordionDetails>
              Have questions or need assistance? We're here to help! Get in touch with us via email at piggy.rewards.us@gmail.com. Don't forget to include your business name in the subject line.
            </AccordionDetails>
          </Accordion>
          {/* <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
            >
              Accordion Actions
            </AccordionSummary>
            <AccordionDetails>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
              malesuada lacus ex, sit amet blandit leo lobortis eget.
            </AccordionDetails>
            <AccordionActions>
              <Button>Cancel</Button>
              <Button>Agree</Button>
            </AccordionActions>
          </Accordion> */}
        </Grid>
      </Grid>
      <SnackbarAlert />
    </Container>
  )
}