import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";

import { useState } from 'react';
import { Link } from "react-router-dom";

import { doesMerchantExist, isMerchantRegistered, setIsMerchantRegistered } from '../../api/merchant/merchant-service';
import { createAccount } from '../../api/auth/auth-service';
import { JoinFormValues } from '../../interfaces/interfaces';
import { useBoundStore } from '../../store/store';

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" to="/copyright">
        Piggy Rewards
      </Link>{' '}
      {new Date().getFullYear()}
      {'. '}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function JoinPage() {

  // FV = Form Value
  const [ showError, setShowError ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState('');
  const [ joinFormValues, setJoinFormValues ] = useState<JoinFormValues>({ merchantId: '', email: '', password: ''});
  const navigate = useNavigate();

  const setIsSignedIn = useBoundStore((state) => state.setIsSignedIn);
  const setMerchantId = useBoundStore((state) => state.setMerchantId);
  const setMerchantAccountId = useBoundStore((state) => state.setMerchantAccountId);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const merchantId = data.get('merchantId')?.toString();

    try {
      // Check if merchant doesnt exist
      // cB6FruUB8KijjJ5BwRPi
      // GvAC5UTVwTsnTmuMWDgr

      setIsLoading(true);

      const merchant = await doesMerchantExist(merchantId!);

      if (!merchant) {
        setShowError(true);
        setErrorMessage('The merchant ID does not exist. Please enter a valid merchant ID.');
        setJoinFormValues({ merchantId: '', email: '', password: '' });
        setIsLoading(false);
        return;
      } 

      // If they are a registered merchant, it should NOT allow them to make another account.
      const registeredMerchant = await isMerchantRegistered(merchantId!);

      if (registeredMerchant) {
        setShowError(true);
        setErrorMessage("The merchant already has an account linked. There can only be 1 linked account.");
        setJoinFormValues({ merchantId: '', email: '', password: '' });
        setIsLoading(false);
        return;
      }

      const userCredential = await createAccount(joinFormValues);
      const merchantAccountId = userCredential.user.uid;
      await setIsMerchantRegistered(joinFormValues.merchantId, true);
    
      setMerchantId(merchantId!);
      setMerchantAccountId(merchantAccountId);
      setIsSignedIn(true);
      navigate('/dashboard');
      
    } catch (err: any) {
      if (err.code === 'auth/email-already-in-use') {
        setShowError(true);
        setErrorMessage("This email is already in use. Please try again.");
      } else if (err.code === 'auth/invalid-email') {
        setShowError(true);
        setErrorMessage("The email address does not exist. Please try again.");
      } else if (err.code === 'auth/weak-password') {
        setShowError(true);
        setErrorMessage("Passwords should be at least 6 characters. Please try again.");
      }
    }
    setIsLoading(false);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
      {showError ? <Alert variant="outlined" severity="error" sx={{ marginTop: 2 }}>{errorMessage} </Alert> : <></>}
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Join
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  name="merchantId"
                  required
                  fullWidth
                  id="merchantId"
                  label="Merchant ID"
                  autoFocus
                  value={joinFormValues.merchantId}
                  onChange={(e) => setJoinFormValues((previous) => ({...previous, merchantId: e.target.value}))}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  type="email"
                  value={joinFormValues.email}
                  onChange={(e) => setJoinFormValues((previous) => ({...previous, email: e.target.value}))}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  value={joinFormValues.password}
                  onChange={(e) => setJoinFormValues((previous) => ({...previous, password: e.target.value}))}
                />
              </Grid>
              {/* <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox value="allowExtraEmails" color="primary" />}
                  label="I want to receive inspiration, marketing promotions and updates via email."
                />
              </Grid> */}
            </Grid>
            { isLoading ? (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                <CircularProgress size="1.5rem"/>
              </Button>             
            ) : (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Create account
              </Button>  
            )}
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link to='/signin'>
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}