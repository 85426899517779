//imports
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, setPersistence, browserLocalPersistence } from 'firebase/auth';
import { auth } from '../../utils/firebase';
import { JoinFormValues, SignInFormValues } from '../../interfaces/interfaces';
import { setMerchantAccountInfo } from '../merchant/merchant-service';
import { FirebaseError } from 'firebase/app';


//required if you want to keep logged in after user exits the browser or closes tab
setPersistence(auth, browserLocalPersistence);

//Sign in functionality
export const signIn = async ({ email, password }: SignInFormValues) => {
 const userCredential = await signInWithEmailAndPassword(auth, email, password);
 return userCredential;
};

//Sign up functionality
export const conventionalJoin = async ({ email, password }: JoinFormValues) => {
 const userCredential = await createUserWithEmailAndPassword(auth, email, password);
 return userCredential;
};

export const createAccount = async ({ merchantId, email, password }: JoinFormValues) => {
  
  // check if merchantId exists in the merchants table
  // if merchantId does NOT exist, reject the join and don't even call the signInWithEmailAndPassword.
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const merchantAccount = userCredential.user;
    const merchantAccountId = merchantAccount.uid;
    setMerchantAccountInfo(merchantAccountId, merchantId, email);
    return userCredential;
  } catch (err) {
    throw err;
  }
}

//Sign out functionality
export const SignOut = async () => {
 await signOut(auth);
};

// Check if merchant ID exists
export const doesMerchantIdExist = async () => {

}
