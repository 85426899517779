import './App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useBoundStore } from './store/store';

import JoinPage from '../src/pages/access/JoinPage';
import SignInPage from '../src/pages/access/SignInPage';
import DashboardPage from './pages/dashboard/DashboardPage';
import HelpCenterPage from './pages/resources/HelpCenterPage';
import CopyrightPage from './pages/legal/CopyrightPage';
import Layout from './components/Layout';

const ProtectedRoute = ({ children }: any) => {
  const isSignedIn = useBoundStore((state) => state.isSignedIn);

  if (!isSignedIn) return <Navigate to='/signin' replace />

  return children;
}

function App() {
  const isSignedIn = useBoundStore((state) => state.isSignedIn);

  return (
    <Routes>
      <Route path='/copyright' element={ <CopyrightPage />}></Route>
      { isSignedIn ? (
        <Route element={<Layout />}>
          <Route path='/' element={
            <ProtectedRoute>
              <DashboardPage />
            </ProtectedRoute>}>
          </Route>
          <Route path='/help' element={
            <ProtectedRoute>
              <HelpCenterPage />
            </ProtectedRoute>}>
          </Route>
          <Route path="*" element={<Navigate to='/' replace />} />
        </Route>
      ) : (
        <Route>
          <Route path='/signin' element={<SignInPage />} />
          <Route path='/join' element={<JoinPage />} />
          <Route path="*" element={<Navigate to='/signin' replace />} />
        </Route>
      )}
    </Routes>
  );
}

export default App;
