import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import SettingsIcon from '@mui/icons-material/Settings';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Stack from '@mui/material/Stack';
import { useState } from 'react';

import { MerchantRewardData } from '../interfaces/interfaces';
import AddRewardDialog from './AddRewardDialog';
import RewardOptionsPopver from './RewardOptionsPopover';



// Generate Order Data
function createData(
  id: number,
  date: string,
  name: number,
  shipTo: string,
) {
  return { id, date, name, shipTo };
}

const rows = [
  createData(
    0,
    'Massage',
    1000,
    'Get a free massage on your next visit!'
  ),
  createData(
    1,
    'Haircut',
    2000,
    'Get a free haircut on your next visit!',
  ),
  createData(
    2, 
    'Bagel', 
    150, 
    'Get a free bagel on your next puchase of $10 or more!'
  ),
  createData(
    3,
    'Cookie',
    250,
    'Get a free cookie on your next purchase (no minimum).',
  ),
  createData(
    4,
    'Drink',
    300,
    'Get a free soft drink (limited to coke, diet coke, sprite, ginger ale) on your next visit',
  ),
];

function preventDefault(event: React.MouseEvent) {
  event.preventDefault();
}

export default function RewardsTable({ rewards }: any) {
  const [ openDialog, setOpenDialog ] = useState(false);
  const [ anchorEl, setAnchorEl ] = React.useState<HTMLButtonElement | null>(null);
  const [ selectedReward, setSelectedReward ] = useState<MerchantRewardData>({name: '', points: 0, description: ''});

    // Open 'add reward' dialog
    const handleClickRewardDialog = () => {
      setOpenDialog(true);
    };
  
    // Close 'add reward' dialog
    const handleCloseRewardDialog = () => {
      setOpenDialog(false);
    };

    const handleClickPopover = (event: React.MouseEvent<HTMLButtonElement>, { name, points, description }: MerchantRewardData) => {
      setAnchorEl(event.currentTarget);
      setSelectedReward({ name: name, points: points, description: description });
    };
  
    const handleClosePopover = () => {
      setAnchorEl(null);
    };
  

  return (
    <React.Fragment>
          <Stack direction='row'>
            <Stack flex={1}>
            <Title>
              Current Rewards Offered
            </Title>
            </Stack>
          <Stack justifyContent='end' mb={2}>
            <Button variant='contained' size='small' onClick={handleClickRewardDialog} startIcon={<AddIcon />}>
              Add Reward
            </Button>
          </Stack>
          </Stack>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Reward</TableCell>
            <TableCell>Points</TableCell>
            <TableCell>Description</TableCell>
            <TableCell align='center'><SettingsIcon fontSize='small'/></TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {rewards.map((row: MerchantRewardData, index: any) => (
            <TableRow key={index}>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.points}</TableCell>
              <TableCell>{row.description}</TableCell>
              <TableCell align='center'>
                <IconButton size='small' onClick={(e) => handleClickPopover(e, { name: row.name, points: row.points, description: row.description })}>
                  <MoreVertIcon />
                </IconButton>
                {/* <IconButton size='small' onClick={handleClickPopover}>
                  <DeleteIcon />
                </IconButton> */}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <AddRewardDialog open={openDialog} handleClose={handleCloseRewardDialog}/>
      <RewardOptionsPopver handleClose={handleClosePopover} anchorEl={anchorEl} reward={selectedReward} />
    </React.Fragment>
  );
}