import * as React from 'react';
import Paper from '@mui/material/Paper';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import SnackbarAlert from '../../components/SnackbarAlert';

export default function CopyrightPage() {

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        {/* Recent Orders */}
        <Grid item xs={12}>
          <Paper sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              height: "100%",
            }}>
              All content included on this website, such as text, graphics, logos, images, audio clips, video clips, digital downloads, data compilations, and software, is the property of Piggy Rewards or its content suppliers and protected by international copyright laws. The compilation of all content on this site is the exclusive property of Piggy Rewards, with copyright authorship for this collection by Piggy Rewards, and protected by international copyright laws.

Piggy Rewards reserves all rights not expressly granted in and to the website and its content. Any use of the website content not specifically permitted under these terms is strictly prohibited.

Permission is granted to electronically copy and print hard copy portions of this website for the sole purpose of placing an order with Piggy Rewards or purchasing Piggy Rewards products. You may display and, subject to any expressly stated restrictions or limitations relating to specific material, download or print portions of the material from the different areas of the website solely for your own non-commercial use.

Any other use, including but not limited to the reproduction, distribution, display, or transmission of the content of this website is strictly prohibited, unless authorized by Piggy Rewards. You further agree not to change or delete any proprietary notices from materials downloaded from the website.

Copyright © 2024 Piggy Rewards. All rights reserved.
          </Paper>
        </Grid>
      </Grid>
      <SnackbarAlert />
    </Container>
  )
}