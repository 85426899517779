import * as React from 'react';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Title from '../../../components/Title';

export default function Deposits({icon, title, result}: any) {
  return (
    <>
      <Stack alignItems="center" direction="row" gap={1} marginBottom={1}>
        {icon}
        <Typography color="text.secondary">
          {title}
        </Typography>
      </Stack>
      <Typography component="p" variant="h4">
        {result}
      </Typography>
    </>
  );
}