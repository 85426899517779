import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LogoutIcon from '@mui/icons-material/Logout';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { useNavigate } from "react-router-dom";
import { SignOut } from '../api/auth/auth-service';
import { useBoundStore } from '../store/store';

export function NavMainListItems() {
  const navigate = useNavigate();

  const navigateToDashboard = () => {
    navigate('/dashboard');
  }
  
  return (
    <React.Fragment>
    <ListItemButton onClick={navigateToDashboard}>
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItemButton>
    {/* <ListItemButton>
      <ListItemIcon>
        <ShoppingCartIcon />
      </ListItemIcon>
      <ListItemText primary="Orders" />
    </ListItemButton> */}
  </React.Fragment>
  )
}

export function NavSecondaryListItems() {
  const setIsSignedIn = useBoundStore((state) => state.setIsSignedIn);
  const isSignedIn = useBoundStore((state) => state.isSignedIn);
  const navigate = useNavigate();
  
  const navigateToHelpCenter = () => {
    navigate('/help');
  }

  const handleSignOut = async() => {
    console.log("isSignedIn", isSignedIn);
    await SignOut();
    setIsSignedIn(false);
    navigate('/signin');
  }


  return (   
    <React.Fragment>
      <ListSubheader component="div" inset>
        Resources
      </ListSubheader>
      <ListItemButton onClick={navigateToHelpCenter}>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Help Center" />
      </ListItemButton>
      <ListItemButton onClick={handleSignOut}>
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText primary="Sign out" />
      </ListItemButton>
    </React.Fragment>
  )
}