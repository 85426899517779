import { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { addReward } from '../api/merchant/merchant-service'; 
import { MerchantRewardData, ISnackbarAlert } from '../interfaces/interfaces';
import { useBoundStore } from '../store/store';
import { SnackbarAlertType } from '../enums/enums';

interface Props {
  open: boolean,
  handleClose: () => void,
}

export default function AddRewardDialog({ open, handleClose }: Props) {

  const merchantId = useBoundStore((state) => state.merchantId);
  const setShowSnackbarAlert = useBoundStore((state) => state.setShowSnackbarAlert);
  const setSnackbarAlertMessage = useBoundStore((state) => state.setSnackbarAlertMessage);


  return (
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries((formData as any).entries());
            const name = formJson.name;
            const points = formJson.points;
            const description = formJson.description;
            try {
              const rewardData:MerchantRewardData = { name: name, points: points, description: description }
              await addReward(merchantId, rewardData);
              const alert: ISnackbarAlert = { type: SnackbarAlertType.success, message: 'Reward added!' };
              setSnackbarAlertMessage(alert);
              setShowSnackbarAlert(true);
            } catch (err) {
              console.log('Error while trying to submit form to add merchant reward: ', err);
            }
            handleClose();
          },
        }}
      >
        <DialogTitle>Add a reward</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To add a reward, please enter the reward name, how many points it will require to redeem, and a quick summary of the reward.
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="name"
            label="Reward name"
            fullWidth
            variant="standard"
            placeholder="Ex: Bagel"
          />
          <TextField
            required
            margin="dense"
            id="points"
            name="points"
            label="Points"
            fullWidth
            variant="standard"
            placeholder="Ex: 125"
          />
          <TextField
            required
            margin="dense"
            id="description"
            name="description"
            label="Description"
            fullWidth
            variant="standard"
            placeholder="Ex: Get one free bagel on your next visit. Does not include cream cheese."
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Add reward</Button>
        </DialogActions>
      </Dialog>
  );
}