import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DeleteIcon from '@mui/icons-material/Delete';
import DraftsIcon from '@mui/icons-material/Drafts';

import { deleteReward } from '../api/merchant/merchant-service'; 
import { MerchantRewardData, ISnackbarAlert } from '../interfaces/interfaces';
import { useBoundStore } from '../store/store';
import { SnackbarAlertType } from '../enums/enums';


interface Props {
  handleClose: () => void,
  anchorEl: HTMLButtonElement | null,
  reward: MerchantRewardData
}

export default function RewardOptionsPopover({ handleClose, anchorEl, reward}: Props) {

  const merchantId = useBoundStore((state) => state.merchantId);
  const setShowSnackbarAlert = useBoundStore((state) => state.setShowSnackbarAlert);
  const setSnackbarAlertMessage = useBoundStore((state) => state.setSnackbarAlertMessage);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleDeleteReward = async () => {
    try {
      // implement delete reward
      handleClose();
      await deleteReward(merchantId, reward);
      const alert: ISnackbarAlert = { type: SnackbarAlertType.success, message: 'Reward removed!' };
      console.log("asdasdasd CLOSE POPOVER")
      setSnackbarAlertMessage(alert);
      setShowSnackbarAlert(true);
    } catch (err) {
      console.log("Failed to delete reward: ", err);
    }
  }

  return (
    <div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
          <List>
            <ListItem disablePadding>
              <ListItemButton onClick={handleDeleteReward}>
                <ListItemIcon>
                  <DeleteIcon sx={{ color: 'red' }}/>
                </ListItemIcon>
                <ListItemText>
                  <Typography color={'red'}>Delete</Typography>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Popover>
    </div>
  );
}