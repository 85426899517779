import { create, StateCreator } from 'zustand'
import { SnackbarAlertType } from '../../enums/enums';
import { ISnackbarAlert } from '../../interfaces/interfaces';

export interface UserSlice {
  isSignedIn: boolean,
  setIsSignedIn: (signedIn: boolean) => void,
  merchantId: string,
  setMerchantId: (merchantId: string) => void,
  merchantAccountId: string,
  setMerchantAccountId: (merchantAccountId: string) => void,
  showSnackbarAlert: boolean,
  setShowSnackbarAlert: (showSnackbarAlert: boolean) => void,
  snackbarAlertMessage: ISnackbarAlert,
  setSnackbarAlertMessage: (snackBarAlertMessage: ISnackbarAlert) => void
}

export const createUserSlice: StateCreator<
UserSlice,
  [],
  [],
  UserSlice
> = (set) => ({
  isSignedIn: false,
  setIsSignedIn: (signedIn) => set(() => ({ isSignedIn: signedIn})),
  merchantId: '',
  setMerchantId: (merchantId) => set(() => ({ merchantId: merchantId})),
  merchantAccountId: '',
  setMerchantAccountId: (merchantAccountId) => set(() => ({ merchantAccountId: merchantAccountId})),
  showSnackbarAlert: false,
  setShowSnackbarAlert: (showSnackbarAlert) => set(() => ({ showSnackbarAlert: showSnackbarAlert})),
  snackbarAlertMessage: { type: SnackbarAlertType.default, message: ''},
  setSnackbarAlertMessage: (snackbarAlertMessage) => set(() => ({ snackbarAlertMessage: snackbarAlertMessage}))
})



