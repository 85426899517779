import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import { useBoundStore } from '../store/store';
import { SnackbarAlertType } from '../enums/enums';

import { AlertColor } from '@mui/material/Alert';

export default function SnackbarAlert() {

  const showSnackbarAlert = useBoundStore((state) => state.showSnackbarAlert);
  const setShowSnackbarAlert = useBoundStore((state) => state.setShowSnackbarAlert);
  const snackbarAlertMessage = useBoundStore((state) => state.snackbarAlertMessage);
  //const a = AlertColor.(snackbarAlertMessage.type)
  const a: AlertColor = 'success'


  const convertToAlertColor = (typeOfAlertColor: string) => {
    if (typeOfAlertColor == 'success') {
      const alertColor: AlertColor = typeOfAlertColor;
      return alertColor;
    } else if ( typeOfAlertColor == 'error' ) {
      const alertColor: AlertColor = typeOfAlertColor;
      return alertColor;
    } else if ( typeOfAlertColor == 'info' ) {
      const alertColor: AlertColor = typeOfAlertColor;
      return alertColor;
    } else if ( typeOfAlertColor == 'warning' ) {
      const alertColor: AlertColor = typeOfAlertColor;
      return alertColor;
    }
  }

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      setShowSnackbarAlert(false);
      return;
    }

    setShowSnackbarAlert(false);
  };
  return (
    <Snackbar open={showSnackbarAlert} autoHideDuration={5000} anchorOrigin={{vertical: 'bottom', horizontal: 'left'}} onClose={handleClose}>
    <Alert
      onClose={handleClose}
      severity={convertToAlertColor(snackbarAlertMessage.type)}
      variant="filled"
      sx={{ width: '100%' }}
    >
      {snackbarAlertMessage.message}
    </Alert>
    </Snackbar>
  )
}