import { create } from 'zustand'
import { createJSONStorage, persist, devtools } from "zustand/middleware";
import { UserSlice, createUserSlice } from './slices/auth';
//import type {} from '@redux-devtools/extension' // required for devtools typing

// interface BearState {
//   bears: number
//   increase: (by: number) => void
// }

export const useBoundStore = create<UserSlice>()((...a) => ({
  ...createUserSlice(...a),
  ...persist(createUserSlice, {
    name: 'user-slice',
    storage: createJSONStorage(() => localStorage),
  })(...a),
}))