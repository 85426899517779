import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { Link, useNavigate } from "react-router-dom";
import { useState } from 'react';

import { SignInFormValues } from '../../interfaces/interfaces';
import { signIn } from '../../api/auth/auth-service';
import { getMerchantId } from '../../api/merchant/merchant-service';
import { useBoundStore } from '../../store/store';


function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" to="/copyright">
        Piggy Rewards
      </Link>{' '}
      {new Date().getFullYear()}
      {'. '}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function SignInPage() {

  const [ signInFormValues, setSignInFormValues ] = useState<SignInFormValues>({ email: '', password: ''});
  const [ isLoading, setIsLoading ] = useState(false);
  const [ showError, setShowError ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState('');


  const setIsSignedIn = useBoundStore((state) => state.setIsSignedIn);
  const setMerchantAccountId = useBoundStore((state) => state.setMerchantAccountId);
  const setMerchantId = useBoundStore((state) => state.setMerchantId);


  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    //const data = new FormData(event.currentTarget);

    // const email = data.get('email')?.toString();
    // const password = data.get('password')?.toString();

    setIsLoading(true);
    try {
      const userCredential = await signIn(signInFormValues);
      const merchantAccountId = userCredential.user.uid;

      // Grab the merchant id by using the merchant account ID
      const merchantId = await getMerchantId(merchantAccountId);

      // When sign is successful, set isSignedIn to global store
      setIsSignedIn(true);
      setMerchantAccountId(merchantAccountId);
      setMerchantId(merchantId);
      // Redirect to home page, when sign in is successful.
      navigate('/dashboard', { replace: true });
    } catch (err: any) {
      // Reset sign in form values back to empty if there is an error.
      // setSignInFormValues({ email: '', password: '' });

      // Checks for firebase error
      if (err.code === 'auth/invalid-email') {
        setShowError(true);
        setErrorMessage('The email address does not exist. Please try again.');
      } else {
        setShowError(true);
        setErrorMessage('The email address or password was incorrect. Please try again.');
      }
    }
    setIsLoading(false);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        {showError ? <Alert variant="outlined" severity="error" sx={{ marginTop: 2 }}>{errorMessage} </Alert> : <></>}
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={signInFormValues.email}
              onChange={(e) => setSignInFormValues((previous) => ({...previous, email: e.target.value}))}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={signInFormValues.password}
              onChange={(e) => setSignInFormValues((previous) => ({...previous, password: e.target.value}))}
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            { isLoading ? (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                <CircularProgress size="1.5rem"/>
              </Button>             
            ) : (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>  
            )}
            <Grid container justifyContent="flex-end">
              {/* <Grid item xs>
                <Link to='/'>
                  Forgot password?
                </Link>
              </Grid> */}
              <Grid item>
                <Link to="/join">
                  {"Don't have an account?"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}