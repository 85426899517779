import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Deposits from './components/Deposits';
import RewardsTable from '../../components/RewardsTable';
import SnackbarAlert from '../../components/SnackbarAlert';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined';
import { useEffect, useState } from 'react';
import { useBoundStore } from '../../store/store';
import { MerchantRewardData } from '../../interfaces/interfaces';

import { db } from '../../utils/firebase';
import { doc, onSnapshot } from 'firebase/firestore';

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

export default function DashboardPage() {
  // MUI
  const [open, setOpen] = React.useState(true);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  // Store states
  const merchantId = useBoundStore((state) => state.merchantId);

  // Function states
  const [ merchantName, setMerchantName ] = useState('');
  const [ merchantRewards, setMerchantRewards ] = useState<MerchantRewardData[]>([]);
  const [ totalPointsAwarded, setTotalPointsAwarded ] = useState(0);
  const [ totalMoneySpent, setTotalMoneySpent ] = useState(0);

  const convertNumberToMoney = (money: number) => {
    const usdFormat = new Intl.NumberFormat('en-Us', {style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol'}).format(money);
    return usdFormat.toString(); 
  }

  useEffect(() => {
    // Set up a listener
    const docRef = doc(db, 'merchants', merchantId);
    //let rewards;
    const unsubscribe = onSnapshot(docRef, (snapshot) => {

      const merchantData = snapshot.data();
      setMerchantName(merchantData?.name);
      setMerchantRewards(merchantData?.rewards);
      setTotalPointsAwarded(merchantData?.totalPointsAwarded);
      const moneySpent = (merchantData?.totalPointsAwarded / 10)
      setTotalMoneySpent(moneySpent);
      //rewards = snapshot.data()?.rewards; 
    })

  return () => unsubscribe();
  }, [])


  return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <Tooltip title="This is currently how much Piggy users need to spend at your business to earn 10 points. This is currently not customizable." arrow>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              height: 120,
            }}
          >
            <Deposits icon={<CurrencyExchangeOutlinedIcon fontSize="small" />} title={'Points conversion'} result={'$1 = 10 pts'}/>
          </Paper>
          </Tooltip>
        </Grid>
        <Grid item xs={3}>
          <Tooltip title="This is the total points that have been awarded to Piggy users for spending on your business." arrow>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              height: 120,
            }}
          >
            <Deposits icon={<LoyaltyIcon fontSize="small" />} title={'Points awarded'} result={totalPointsAwarded.toLocaleString()}/>
          </Paper>
          </Tooltip>
        </Grid>
        <Grid item xs={3}>
        <Tooltip title="This is the total money that has been spent on your business by Piggy users." arrow>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              height: 120,
            }}
          >
            <Deposits icon={<MonetizationOnOutlinedIcon fontSize="small" />} title={'Money spent'} result={convertNumberToMoney(totalMoneySpent)}/>
          </Paper>
          </Tooltip>
        </Grid>
        <Grid item xs={3}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'row',
              height: 120,
            }}
          >
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <RewardsTable rewards={merchantRewards}/>
          </Paper>
        </Grid>
      </Grid>
      <SnackbarAlert />
    </Container>
  );
}